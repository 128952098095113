import React, { useEffect, Fragment } from "react";

const AppFooter = () => {
  return (
    <Fragment>
      <div className="app-footer">
        <div className="app-footer__inner">
          <div className="app-footer-left"></div>
          <div className="app-footer-center" style={{ opacity: ".7" }}>
            Copyright &copy; 2024 Orange Eight Software, LLC
          </div>
          <div className="app-footer-right">
            <a
              href="https://www.iubenda.com/privacy-policy/65409511"
              className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe"
              title="Privacy Policy"
            >
              Privacy Policy
            </a>
            <a
              href="https://www.iubenda.com/privacy-policy/65409511/cookie-policy"
              className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe"
              title="Cookie Policy"
            >
              Cookie Policy
            </a>
            <a
              href="https://www.iubenda.com/terms-and-conditions/65409511"
              className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe"
              title="Terms and Conditions"
            >
              Terms and Conditions
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AppFooter;
