import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import supabase from '../lib/supabase';

const MagicLinkHandler = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleMagicLinkRedirect = async () => {
      let hash = window.location.hash.substring(1);
      if (hash.startsWith('/')) {
        hash = hash.substring(1);
      }

      const params = new URLSearchParams(hash);
      const type = params.get('type');
      const access_token = params.get('access_token');
      const refresh_token = params.get('refresh_token');

      if (access_token && refresh_token) {
        const { error } = await supabase.auth.setSession({ access_token, refresh_token });

        if (error) {
          console.error('Error setting session:', error.message);
        } else {
          if (type === 'recovery') {
            sessionStorage.setItem('passwordRecoveryFlow', 'true');
            navigate('/admin/new_password');
          } else if (type === 'signup' ) {
            navigate('/registration/locations');
          } else {
            navigate('/');
          }
        }
      }
    };

    handleMagicLinkRedirect();
  }, [navigate]);

  return null; // This component does not render anything
};

export default MagicLinkHandler;
