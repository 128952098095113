import React, { useEffect, useState, Suspense, lazy, Fragment, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom';
import Loader from "react-loaders";

import { LocationProvider } from './context/LocationContext';
import { AccountContext } from './context/AccountContext';

import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/general.css';

import { ToastContainer } from "react-toastify";

import AppHeader from "./Layout/AppHeader/";
import AppSidebar from "./Layout/AppSidebar/";
import AppFooter from "./Layout/AppFooter/";
import QuickstartPanel from './components/Quickstart';
import MagicLinkHandler from './components/MagicLinkHandler';

//Public pages
const Login = lazy(() => import('./public/login'));
const Register = lazy(() => import('./public/register'));
const Home = lazy(() => import('./public/index'));
const ForgotPassword = lazy(() => import('./public/forgot_password'));

//Logged in pages
const OpsDashboard = lazy(() => import('./pages/dashboards/ops'));

const HuddleBoardReport = lazy(() => import('./pages/reports/huddle_board'));

const InventoryLedger = lazy(() => import('./pages/inventory/ledger'));
const InventoryLocations = lazy(() => import('./pages/inventory/zones/inventory_zones'));
const InventoryLocationComponents = lazy(() => import('./pages/inventory/zones/inventory_zone_components'));

const IngredientsList = lazy(() => import('./pages/ingredients/ingredients'));
const RecipeList = lazy(() => import('./pages/ingredients/recipes'));
const ModifierList = lazy(() => import('./pages/ingredients/modifiers'));
const RecipeDetail = lazy(() => import('./pages/ingredients/recipeDetail'));

const BrandsList = lazy(() => import('./pages/admin/ingredients/brands'));
const SuppliersList = lazy(() => import('./pages/admin/ingredients/suppliers'));

const BakeGroupsList = lazy(() => import('./pages/admin/recipes/bake_groups'));
const PrepCategoriesList = lazy(() => import('./pages/admin/recipes/prep_categories'));
const PrepCategoryDetail = lazy(() => import('./pages/admin/recipes/prep_category_detail'));

const Checklists = lazy(() => import('./pages/admin/checklists/checklists'));
const ChecklistDetail = lazy(() => import('./pages/admin/checklists/checklist_details'));

const AccountInfoEdit = lazy(() => import('./pages/account/account'));
const NewPassword = lazy(() => import('./pages/account/new_password'));
const LocationsList = lazy(() => import('./pages/account/locations'));
const Logout = lazy(() => import('./pages/account/logout'));

const TabletInfo = lazy(() => import('./pages/tablet_admin/info'));
const KDSAdmin = lazy(() => import('./pages/tablet_admin/kds'));

const InventoryFullCount = lazy(() => import('./pages/inventory/counts/full_count'));
const InventorySingleCount = lazy(() => import('./pages/inventory/counts/single_count'));
const InventoryCreateOrder = lazy(() => import('./pages/inventory/ordering/create_order'));
const InventoryReceiveOrder = lazy(() => import('./pages/inventory/ordering/receive_order'));

const RegistrationLocations = lazy(() => import('./pages/registration/Locations'));
const RegistrationIntegrations = lazy(() => import('./pages/registration/Integrations'));
const RegistrationComplete = lazy(() => import('./pages/registration/Complete'));

//Errors
const Expired = lazy(() => import('./public/expired'));

//OAuth Connections
const AccountConnect = lazy(() => import('./pages/account/connect'));
const SquareOAuth = lazy(() => import('./pages/account/connect/oauth/square'));

const RedirectToLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check if the user is trying to access a URL other than login/register
    if (location.pathname !== "/login" && location.pathname !== "/account/logout" && location.pathname !== "/register" && location.pathname !== "/forgot_password") {
      localStorage.setItem('preLoginPath', location.pathname + location.search);
    }
    navigate('/login', { replace: true });
  }, [navigate, location]);
  
  return null;
};

const App = () => {
  const [isRegistration, setIsRegistration] = useState(false);
  const { hasSession } = useContext(AccountContext);

  useEffect(() => {
    const checkQueryString = () => {
      const hash = window.location.hash;
      const params = new URLSearchParams(hash.substring(1)); // Remove the '#' before parsing
      const error = params.get("error");
      const errorCode = params.get("error_code");
      const errorDescription = params.get("error_description");

      if (
        error === "access_denied" &&
        errorCode === "403" &&
        errorDescription === "Email link is invalid or has expired"
      ) {
        window.location.href = "/expired";
      }
    };

    checkQueryString();
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.iubenda.com/iubenda.js";
    script.async = true;
    document.body.appendChild(script);

    const autoBlockingScript = document.createElement("script");
    autoBlockingScript.src = "https://cs.iubenda.com/autoblocking/3734295.js";
    autoBlockingScript.async = true;
    document.body.appendChild(autoBlockingScript);

    const gppStubScript = document.createElement("script");
    gppStubScript.src = "//cdn.iubenda.com/cs/gpp/stub.js";
    gppStubScript.async = true;
    document.body.appendChild(gppStubScript);

    const csScript = document.createElement("script");
    csScript.src = "//cdn.iubenda.com/cs/iubenda_cs.js";
    csScript.async = true;
    document.body.appendChild(csScript);

    // Cleanup scripts on unmount
    return () => {
      document.body.removeChild(script);
      document.body.removeChild(autoBlockingScript);
      document.body.removeChild(gppStubScript);
      document.body.removeChild(csScript);
    };
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      setIsRegistration(
        window.location.pathname.startsWith("/registration/")
      );
    };
  
    // Set initial state when the component mounts
    handleRouteChange();
  
    // Listen for route changes
    window.addEventListener("popstate", handleRouteChange);
  
    return () => {
      // Clean up the event listener on unmount
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, []);

  return (
    <Router>
      <MagicLinkHandler />
      {hasSession === null ? (
        <div className="loader-container">
          <div className="loader-container-inner">
            <div className="text-center">
              <Loader type="ball-pulse-rise" />
            </div>
            <h6 className="mt-5">
              Loading...
            </h6>
          </div>
        </div>
      ) : hasSession ? (
          <LocationProvider>
            <Suspense
              fallback={
                <div className="loader-container">
                  <div className="loader-container-inner">
                    <div className="text-center">
                      <Loader type="ball-pulse-rise" />
                    </div>
                    <h6 className="mt-5">
                      Loading...
                    </h6>
                  </div>
                </div>
              }
            >
              <Fragment>
                <AppHeader showDropdowns={!isRegistration} />
                <div className="app-main">
                {!isRegistration && <AppSidebar />}
                  <div className="app-main__outer">
                    <div className="app-main__inner">
                      <Routes>
                        <Route path="/dashboards/ops" element={<OpsDashboard />} />

                        <Route path="/reports/huddle_board" element={<HuddleBoardReport />} />

                        <Route path="/inventory" element={<InventoryLedger />} />
                        <Route path="/inventory/full_count" element={<InventoryFullCount />} />
                        <Route path="/inventory/single_count" element={<InventorySingleCount />} />
                        <Route path="/inventory/new_order" element={<InventoryCreateOrder />} />
                        <Route path="/inventory/receive_order" element={<InventoryReceiveOrder />} />
                        <Route path="/inventory/inventory_zones" element={<InventoryLocations />} />
                        <Route path="/inventory/inventory_zones/detail/:inventory_zone_id" element={<InventoryLocationComponents />} />

                        <Route path="/ingredients" element={<IngredientsList />} />
                        <Route path="/ingredients/modifiers" element={<ModifierList />} />
                        <Route path="/ingredients/recipes" element={<RecipeList />} />
                        <Route path="/ingredients/recipes/detail/:item_id" element={<RecipeDetail />} />

                        <Route path="/admin/brands" element={<BrandsList />} />
                        <Route path="/admin/suppliers" element={<SuppliersList />} />
                        <Route path="/admin/bake_groups" element={<BakeGroupsList />} />
                        <Route path="/admin/prep_categories" element={<PrepCategoriesList />} />
                        <Route path="/admin/prep_categories/detail/:prep_category_id" element={<PrepCategoryDetail />} />
                        <Route path="/admin/checklists" element={<Checklists />} />
                        <Route path="/admin/checklists/detail/:checklist_id" element={<ChecklistDetail />} />

                        <Route path="/account" element={<AccountInfoEdit />} />
                        <Route path="/account/new_password" element={<NewPassword />} />
                        <Route path="/account/locations" element={<LocationsList />} />
                        <Route path="/account/connect" element={<AccountConnect />} />
                        <Route path="/account/logout" element={<Logout />} />

                        <Route path="/tablets/info" element={<TabletInfo />} />
                        <Route path="/tablets/kds" element={<KDSAdmin />} />
                        <Route path="/tablets/special" element={<KDSAdmin />} />

                        <Route path="/registration/locations" element={<RegistrationLocations />} />
                        <Route path="/registration/integrations" element={<RegistrationIntegrations />} />
                        <Route path="/registration/complete" element={<RegistrationComplete />} />

                        <Route path="/square-oauth-redirect" element={<SquareOAuth />} />

                        <Route path="/*" element={<Navigate to="/dashboards/ops" replace />} />

                      </Routes>
                    </div>
                  </div>
                  {!isRegistration && <QuickstartPanel />}
                </div>
                <AppFooter />
                <ToastContainer/>
              </Fragment>
            </Suspense>
          </LocationProvider>
      ) : (
        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-pulse-rise" />
                </div>
                <h6 className="mt-5">
                  Loading...
                </h6>
              </div>
            </div>
          }
        >
          <Routes>
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot_password" element={<ForgotPassword />} />

            <Route path="/expired" element={<Expired />} />

            <Route path="/" element={<Home />} />
            <Route path="/*" element={<RedirectToLogin />} />
          </Routes>
        </Suspense>
      )}
    </Router>
  );
};

export default App;
