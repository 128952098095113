import React, { useState } from 'react';
import { Collapse, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faCircleCheck } from '@fortawesome/free-solid-svg-icons';

const Step = ({ title, icon, content, complete, disabled = false }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        if (!disabled) {
            setIsOpen(!isOpen);
        }
    };

    // Determine the button's class dynamically based on the disabled state
    const buttonClass = `step-header ${disabled ? 'disabled' : ''}`;

	//console.log("icon",icon);

    return (
        <>
            <Button className={buttonClass} onClick={toggleAccordion} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
					{icon?.toLowerCase() === 'check' && (
                    	<FontAwesomeIcon icon={faCircleCheck} color={complete ? '#3ac47d' : 'gray'} style={{ marginRight: '10px' }} />
					)}
                    {title}
                </span>
                <FontAwesomeIcon icon={isOpen ? faCaretUp : faCaretDown} style={{marginRight:'5px'}}/>
            </Button>
            <Collapse isOpen={isOpen}>
                <div className="step-content">
                    {content}
                </div>
            </Collapse>
        </>
    );
};

export default Step;
