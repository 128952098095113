import React from "react";
import { useSelector, useDispatch } from "react-redux";

import Nav from "../AppNav/VerticalNavWrapper";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PerfectScrollbar from "react-perfect-scrollbar";
import HeaderLogo from "../AppLogo";

import { setEnableMobileMenu } from "../../reducers/ThemeOptions";

const AppSidebar = () => {
  const dispatch = useDispatch();

  // Redux state
  const {
    enableMobileMenu,
  } = useSelector((state) => ({
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  }));

  const toggleMobileSidebar = () => {
    dispatch(setEnableMobileMenu(!enableMobileMenu));
  };

  return (
    <>
      <div className="sidebar-mobile-overlay" onClick={toggleMobileSidebar} />
      <TransitionGroup>
        <CSSTransition
          component="div"
          className="app-sidebar sidebar-shadow"
          appear={true}
          enter={false}
          exit={false}
          timeout={500}
        >
          <div>
            <HeaderLogo />
            <PerfectScrollbar>
              <div className="app-sidebar__inner">
                <Nav />
              </div>
            </PerfectScrollbar>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};

export default AppSidebar;
